import React from 'react'
import { useSelector } from 'react-redux';
import KidsSetPrice from './KidsSetPrice';
import {
  bundles as bundlesImg,
  desserts,
  dips,
  drinks,
  kidsLeft,
  kidsRight,
  largePizza,
  mains,
  pizza,
  starters
} from '../img/menu/category-images/category-image-exports';
import { useConfig } from '../helpers/useConfig';

const categoryImages = {
  bundles: bundlesImg,
  desserts: desserts,
  dips: dips,
  drinks: drinks,
  kidsleft: kidsLeft,
  kidsright: kidsRight,
  largepizza: largePizza,
  mains: mains,
  pizza: pizza,
  starters: starters
}

export const ItemMenuHeader = ({
  category,
  subcategory,
  categoryIdx,
  subcatIdx,
  subCategoryHeaderRefs,
  navigateTo
}) => {
  const isSmallScreen = useSelector(state => state.session.isSmallScreen);
  const piccoloMenuIds = useSelector(state => state.session.frontEndConfig?.piccoloMenuIds);
  const piccoloTitle = useSelector(state => state.session.frontEndConfig?.piccoloMenuTitle);
  const { piccoloMenuIntroduction, piccoloMenuIntroductionMobile } = useSelector(state => state.session.frontEndConfig);
  const isKids = category.name.toLowerCase().includes('kids');
  const piccoloItem = subcategory.itemsToRender?.find(item => piccoloMenuIds?.includes(item.id));
  const config = useConfig();
  const brand = config?.BRAND;

  const categoryHeaderImage = (image, idx, side = '') => {
    if (!image) return <></>;
    const lowerCaseImg = image.toLowerCase();
    let topStyle = 0;
    if (isSmallScreen) {
      if (lowerCaseImg === 'drinks') {
        topStyle = 30;
      }
      else if (lowerCaseImg === 'kidsleft' || lowerCaseImg === 'kidsright') {
        topStyle = 0
      }
      else if (lowerCaseImg === 'mains' || lowerCaseImg === 'pizza' || lowerCaseImg === 'starters') {
        topStyle = 25;
      }
      else {
        topStyle = 17;
      }
    }
    const parsedImage = lowerCaseImg === 'kids' ? 'kidsright' : lowerCaseImg;
    return (
      categoryImages[parsedImage] && (
        <div className={`category-image-container  ${lowerCaseImg.includes('kids') ? 'is-kids' : ''} ${side}`}>
          <img
            src={categoryImages[parsedImage]}
            alt={parsedImage || ''}
            className={`category-image`}
            style={{ top: `${topStyle}px` }}
          />
        </div>
      )
    );
  };

  return (
    <>
      <div className={`category-wrapper ${subcategory.imageName ? 'has-category-image' : ''} ${subcategory.imageName}  ${isKids ? 'is-kids' : ''}`}>
        <div className={`category-content-wrapper`}>
          {subcatIdx === 0 && isKids && subcategory.imageName && categoryHeaderImage('kidsleft', subcatIdx, 'left') || ''}
          <div className={`category-content`}>
            {
              subcatIdx === 0 &&
              <div
                className={`category-title-container`}
                ref={(subCat) => {
                  if (!subCategoryHeaderRefs.current[categoryIdx]) {
                    subCategoryHeaderRefs.current[categoryIdx] = [];
                  }
                  subCategoryHeaderRefs.current[categoryIdx][subcatIdx] = subCat;
                }}
                id={`${category.name}`}
              >

                {isKids ?
                  <div className='piccolo-title-container'>
                    <h1 className='category-title is-kids'>
                      {piccoloTitle ?? category.name}
                    </h1>
                    {
                      piccoloMenuIntroduction || piccoloMenuIntroductionMobile ?
                        <h2 className='category-subtitle'>{(isSmallScreen ? piccoloMenuIntroductionMobile : piccoloMenuIntroduction).replace("[PRICE]", `${brand === 'PE' ? '£' : '€'}${piccoloItem?.displayPrice.toFixed(2)}`)}</h2> : <></>
                    }
                  </div>
                  :
                  <h1 className='category-title'>
                    {category.name}
                  </h1>
                }
              </div>
            }
            {
              category.subCategories?.length > 1 &&
              <h1
                className={'sub-category-title'}
                ref={(subCat) => {
                  if (!subCategoryHeaderRefs.current[categoryIdx]) {
                    subCategoryHeaderRefs.current[categoryIdx] = [];
                  }
                  subCategoryHeaderRefs.current[categoryIdx][subcatIdx + 1] = subCat;
                }}
                id={`${category.name}-${subcategory.name}`}
              >{subcategory.name}</h1>
            }
          </div>
          {subcatIdx === 0 && subcategory.imageName && categoryHeaderImage(subcategory.imageName, subcatIdx, 'right') || ''}
        </div>

      </div>
      {
        isKids ? (() => {
          return piccoloItem ? (
            <KidsSetPrice
              item={piccoloItem}
              navigateTo={navigateTo}
            />
          ) : null;
        })() : null
      }
    </>
  )
}