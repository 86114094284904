import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import errorIcon from './../img/icons/error.svg';
import closeError from './../img/icons/clear.svg';
import chevronDown from '../img/icons/chevronDown.svg';
import chevronUp from '../img/icons/chevronUp.svg';
import { Tags } from './Tags';
import DietaryIcons from './DietaryIcons';

const DropDownField = ({
  title,
  name,
  value,
  valueCallback = () => { },
  error,
  errorCallback = () => { },
  required,
  options,
  id,
  defaultValue = '',
  hasExtraMarginBottom = true,
  hideErrorMessage = false,
  greyBg = false,
  flexibleWidth = false,
  titleExtraIdentifier, // Used to identify / label the component and differentiate from other mapped components using additional context for screen readers MV
  placeHolder,
  hasGap,
  collapse,
  icon,
  disabled,
  currentTier,
  dropdownErrorIcon,
  isDeliveryAddress = false
}
) => {
  const identifier = titleExtraIdentifier ? titleExtraIdentifier.replace(/\s/g, '') : '';
  const [selectorOpen, setSelectorOpen] = useState(false);
  const inputRef = useRef({ current: {} });
  const selectRef = useRef();
  const [isFocused, setIsFocused] = useState({ focused: false, activeOption: false, idx: false });
  const [shouldHangRight, setShouldHangRight] = useState(false);
  const updateValue = (value) => {
    setSelectorOpen(false);
    valueCallback(name, value);
    errorCallback(name, '');
  };

  const handleClear = () => {
    valueCallback(name, '');
    errorCallback(name, '');
    inputRef?.current?.focus();
  };

  const moveCursor = (event, isDown, tabKey, idx) => {
    if (!selectorOpen && !tabKey) {
      event.preventDefault();
      const idx = value ? options.findIndex(o => o.value === value) : 0;
      setFocus(true, options[idx].value, idx);
      scrollToOption(idx);
      setSelectorOpen(true);
      return;
    } else if (!selectorOpen && tabKey) {
      return;
    }
    event.preventDefault();
    const activeOptionIdx = options.findIndex(o => o.value === isFocused.activeOption);
    const nextIdx = isDown ? activeOptionIdx + 1 : activeOptionIdx - 1;
    let finalIdx;
    if (idx !== undefined) finalIdx = idx;
    else if (nextIdx > options.length - 1 || nextIdx < 0) {
      return;
    } else {
      finalIdx = nextIdx;
    }
    scrollToOption(finalIdx);
    setFocus(true, options[finalIdx].value, finalIdx);
  };

  const handleInteraction = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        moveCursor(e, true);
        break;
      case 'ArrowUp': {
        moveCursor(e, false);
        break;
      }
      case 'Tab': {
        if (selectorOpen) {
          e.preventDefault();
          if (value) {
            updateValue(value);
          } else {
            updateValue(isFocused.activeOption);
          }
          setFocus(true);
        }
        break;
      }
      case 'Escape': {
        e.preventDefault();
        setSelectorOpen(false);
        setFocus(true);
        break;
      }
      case ' ': {
        e.preventDefault();
        handleEnter();
        break;
      }
      case 'Enter': {
        e.preventDefault();
        handleEnter();
        break;
      }
      case 'Home': {
        e.preventDefault();
        moveCursor(e, false, false, 0);
        break;
      }
      case 'End': {
        e.preventDefault();
        moveCursor(e, false, false, options.length - 1);
        break;
      }
      case 'PageUp': {
        e.preventDefault();
        moveCursor(e, false, false, isFocused.idx - 10 < 0 ? 0 : isFocused.idx - 10);
        break;
      }
      case 'PageDown': {
        e.preventDefault();
        moveCursor(e, false, false, isFocused.idx + 10 > options.length - 1 ? options.length - 1 : isFocused.idx + 10);
        break;
      }
      default: {
        let valueFound = false;

        const valueToApply = options.reduce((valueToReturn, option) => {
          if (!valueFound && option.label.toString().split('')[0].toLowerCase() === e.key.toLowerCase()) {
            valueFound = true;
            return option.value;
          }
          return valueToReturn;
        }, '');

        if (valueToApply) {
          valueCallback(name, valueToApply);
          setIsFocused(true);
        }
      }
    }
  };

  const handleEnter = () => {
    if (selectorOpen) {
      if (value) {
        updateValue(value);
      } else {
        updateValue(isFocused.activeOption);
      }
      setFocus(true);
    } else {
      setSelectorOpen(true);
      const idx = value ? options.findIndex(o => o.value === value) : 0;
      scrollToOption(idx);
      setFocus(true, options[idx].value, idx);
    }
  };

  const scrollToOption = (idx) => {
    const activeElement = [...selectRef?.current?.children].find(c => c.id === `${identifier}-option-${idx}`);
    activeElement?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (selectorOpen && e.target !== selectRef.current) {
        setSelectorOpen(false);
        setFocus(false);
      }
    };

    if (selectorOpen) {
      setTimeout(() => { // Prevents this reacting to initial opening click
        window.addEventListener('click', handleClickOutside);
      }, 100)
    }
    if (isFocused.focused === true) {
      window.addEventListener('keydown', handleInteraction);
    }
    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleInteraction);
    };
  }, [selectorOpen, isFocused]);

  const handleClick = () => {
    if (disabled) return;
    setSelectorOpen(!selectorOpen);
    setFocus(false);
  };
  const handleFocus = () => {
    if (disabled) return;
    !isFocused.focused && setFocus(true);
  };
  const listBoxId = `${identifier}-combobox`;
  const comboBoxId = `${identifier}-${name}`;

  const setFocus = (focused, activeOption = false, idx = false) => setIsFocused({ focused, activeOption, idx });

  useLayoutEffect(() => {
    setShouldHangRight(window.innerWidth < selectRef.current?.getBoundingClientRect()?.right);
  }, [selectorOpen]);

  return (
    <div className={`drop-down-field block w100 ${hasExtraMarginBottom ? 'mb-5' : 'mb-0'} ${disabled ? 'disabled' : ''}`} id={id}>
      {title && required &&
        <label
          className='dropdown-label'
          htmlFor={comboBoxId}
          aria-disabled={disabled}
        >
          {title} {required ? '*' : ''}
        </label>
      }
      <div className={`field is-relative ${value === '' ? 'placeholder' : ''}  ${hasExtraMarginBottom ? 'mb-1' : ''}`} ref={inputRef}>
        <div
          name={name}
          className={`select ${greyBg ? 'grey-bg' : ''} ${error && !selectorOpen ? 'error' : ''} ${hasGap ? 'has-gap' : ''} ${collapse ? 'collapse' : ''}`}
          onKeyDown={handleInteraction}
          onClick={handleClick}
          onFocus={handleFocus}
          onBlur={(e) => {
            if (disabled) return;
            if (e.relatedTarget?.id?.includes(`${identifier}-option-`)) return;
            setSelectorOpen(false);
            setFocus(false);
          }}
          role='combobox'
          tabIndex={0}
          aria-autocomplete='none'
          aria-controls={listBoxId}
          aria-expanded={selectorOpen}
          aria-activedescendant={`${identifier}-option-${options.findIndex(o => o.value === value)}`}
          aria-required={required}
          aria-label={identifier}
          id={comboBoxId}
        >
          <span>
            {
              value ?
                options?.filter((option) => option.value === (value ? value : defaultValue))[0]?.label
                :
                placeHolder
            }
          </span>
          {
            error && !selectorOpen && (
              <img
                src={dropdownErrorIcon || closeError}
                className={dropdownErrorIcon ? 'select-icon' : ''}
                alt="errorIcon"
                onClick={handleClear}
              />
            )
          }
          {
            !error && (
              <img style={{opacity: disabled ? 0.5 : 1}} src={icon ? icon : selectorOpen ? chevronUp : chevronDown} className='select-icon' alt={icon ? 'input icon' : 'chevron down or up'} />
            )
          }
          {
            error && selectorOpen && (
              <img src={icon ? icon : chevronUp} className='select-icon' alt={icon ? 'input icon' : 'chevron up'} />
            )
          }
        </div>
        <ul
          className={
            `options-wrapper 
            ${greyBg ? 'grey-bg' : ''} 
            ${selectorOpen ? 'open' : ''} 
            ${flexibleWidth ? 'flexible-width' : ''} 
            ${collapse ? 'collapse' : ''} 
            ${shouldHangRight ? 'hang-right' : ''}
            ${isDeliveryAddress ? 'delivery-address' : ''}`
          }
          ref={selectRef}
          id={listBoxId}
          role='listbox'
        >
          {options?.map((option, i) => (
            <li
              key={`${option.label}-${i}`}
              className={`option ${option.value === value ? 'selected' : ''} ${flexibleWidth ? 'flexible-width' : ''} ${isFocused.activeOption === option.value ? 'active' : ''} ${option.isOutOfStock ? 'outOfStock' : ''}`}
              onClick={(e) => option.isOutOfStock ? e.stopPropagation() : updateValue(option.value)}
              role='option'
              tabIndex={-1}
              aria-selected={option.value === value}
              id={`${identifier}-option-${i}`}
            >
              <div className='option-info-container'>
                <div className='option-container' style={option?.dietPreferences?.length === 0 ? { gap: '0px' } : undefined}>
                  <span className='option-label'>
                    {option.label}
                  </span>
                  <div className='dietary-container'>
                    {option.dietPreferences
                      && <DietaryIcons icons={option.dietPreferences} style={{ justifyContent: 'flex-end', marginTop: '2px' }} />}
                  </div>
                </div>
                <span className='option-price'>
                  {option.cost}
                </span>
              </div>
              <Tags
                currentTier={currentTier}
                excludeChildTags={true}
                showLocked={false}
                item={option.option}
                wrapperClass={'tags-wrapper'}
              />
            </li>
          ))}
        </ul>
      </div>
      {
        error && !hideErrorMessage && !selectorOpen ? (
          <div className="level is-mobile is-family-code" style={{ paddingLeft: '6px', marginBottom: -12, pointerEvents: 'none' }}>
            <div className="level-item is-narrow is-shrinkable is-align-top">
              <i className="icon">
                <img src={errorIcon} alt="error" />
              </i>
            </div>
            <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-center">
              <div className='error-message'>{error}</div>
            </div>
          </div>
        ) : <div />
      }
    </div>
  );
};

export default DropDownField;